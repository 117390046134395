<template>
    <section class="qualification">
        <Nav
            class="mt-5 mb-4"
            route-name="IPExchange"
            parent-page-name="Трансфер технологий"
            current-page-name="Повышение квалификации"
        />

        <div class="qualification__main-img d-none d-xl-flex">
            <div class="qualification__blurred-img mt-lg-auto ms-lg-auto d-flex align-items-center ms-lg-4">
                <h2 class="qualification__title mt-auto ms-lg-5">
                    Повышение<br>квалификации
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="research-center__logo ms-auto mt-auto d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="technopark__title technopark__title--dark">
                Повышение<br>квалификации
            </h2>
        </div>

        <div class="research-center__task py-5 mt-5 d-flex flex-column flex-lg-row" v-if="pageInfo.should_show">
            <div class="col-12 col-lg-8 col-xl-5">
                <img
                    class="task__img"
                    src="../../assets/views/qualification/Illustration.png"
                    alt=""
                    width="501"
                    height="296"
                >
            </div>

            <div class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start">
                <h3 class="task__title">{{ pageInfo.title }}</h3>
                <div class="task__text mt-3" v-html="pageInfo.description" />
            </div>
        </div>

        <div class="qualification__programs my-5 mx-2">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="d-flex col-12 col-lg-6 flex-wrap">
                            <strong class="m-1">Формат обучения:</strong>

                            <div class="d-flex flex-wrap m-1">
                                <div class="d-flex ms-4">
                                    <input class="form-check-input" type="radio" name="program-format" id="format-all" :value="''" v-model="filters.program_format">
                                    <label class="form-check-label" for="format-all">
                                        <span class="form-check-input__control"></span>
                                        Все форматы
                                    </label>
                                </div>

                                <div class="d-flex ms-4">
                                    <input class="form-check-input" type="radio" name="program-format" id="format-online" value="ONLINE" v-model="filters.program_format">
                                    <label class="form-check-label" for="format-online">
                                        <span class="form-check-input__control"></span>
                                        Онлайн
                                    </label>
                                </div>

                                <div class="d-flex ms-4">
                                    <input class="form-check-input" type="radio" name="program-format" id="format-offline" value="OFFLINE" v-model="filters.program_format">
                                    <label class="form-check-label" for="format-offline">
                                        <span class="form-check-input__control"></span>
                                        Очный формат
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex col-12 col-lg-6 ms-auto justify-content-end">
                            <input type="radio" class="btn-check" name="sort" id="sort-alphabete" autocomplete="off" value="title" v-model="filters.order_filter">
                            <label class="btn btn-flat sort-btn" for="sort-alphabete">
                                По алфавиту
                                <span class="sort-icon ms-1"></span>
                            </label>
                            <input type="radio" class="btn-check" name="sort" id="sort-date" autocomplete="off" value="date_start" v-model="filters.order_filter">
                            <label class="btn btn-flat sort-btn ms-2" for="sort-date">
                                По дате
                                <span class="sort-icon ms-1"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <hr class="mt-2 mb-4">

                <div class="col-12 col-xl-6 my-2" v-for="(program, index) in programs" :key="index">
                    <div
                        class="card rounded qualification-card h-100"
                        @click="showProgramModal(program)"
                        data-bs-toggle="modal"
                        data-bs-target="#programModal"
                    >
                        <div class="card-body">
                            <h5 class="card-title">{{ program.title }}</h5>
                            <p class="card-text mt-2" v-html="program.description" />
                            <div class="d-flex">
                                <span class="badge rounded-pill text-bg-primary">{{ programFormat(program.program_format) }}</span>
                                <span class="badge rounded-pill text-bg-light ms-2">с {{ formatDate(program.date_start) }}</span>

                                <div class="ms-auto">
                                    <a v-if="program.umd" :href="program.umd" @click.stop class="card-link text-white" target="_blank">УМД программы</a>
                                    <a v-if="program.booklet" :href="program.booklet" @click.stop class="card-link text-white" target="_blank">Буклет программы</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <p v-if="programs.length === 0" class="h4 text-muted text-center my-2">Программ повышения квалификации не найдено...</p>

                <div class="col-12 mt-3" v-if="nextPage">
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-link" @click="paginate">Показать ещё</button>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="qualification__form my-5 py-5">
            <div class="mb-5">
                <a id="registrationForm" class="registrationForm registration-form__title technopark-numbers__title">
                    Заполнить форму регистрации
                </a>
            </div>

            <div class="row d-flex">
                <form class="registration-form row" @submit.prevent="onSubmit">
                    <div class="col-md-6 col-lg-5 mx-auto">
                        <div class="mb-3">
                            <label for="fullname" class="parnter-form__label form-label">ФИО</label>
                            <input
                                id="fullname"
                                v-model="form.fio"
                                type="text"
                                class="parnter-form__input form-control"
                                placeholder="Введите ФИО"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="organization" class="parnter-form__label form-label">Организация</label>
                            <input
                                id="organization"
                                type="text"
                                v-model="form.organization"
                                class="parnter-form__input form-control"
                                placeholder="Введите название организации"
                            >
                        </div>

                        <div class="mb-3">
                            <label for="email" class="parnter-form__label form-label">Email</label>
                            <input
                                id="email"
                                v-model="form.email"
                                type="email"
                                class="parnter-form__input form-control"
                                placeholder="Введите e-mail"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="phone" class="parnter-form__label form-label">Контактный телефон</label>
                            <input
                                id="phone"
                                v-model="form.phone"
                                type="text"
                                class="parnter-form__input form-control"
                                placeholder="Введите номер телефона"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="program" class="parnter-form__label form-label">Программа повышения квалификации</label>
                            <select
                                id="program"
                                v-model="form.program"
                                class="parnter-form__input form-control form-select"
                                required
                            >
                                <option :value="null" selected disabled>Выберите программу...</option>
                                <option v-for="(program, programIndex) in programs" :key="programIndex" :value="program.id">{{ program.title }}</option>
                            </select>
                        </div>

                        <div class="d-flex w-100 justify-content-center">
                            <Button
                                variant="blue"
                                type="submit"
                            >
                                <template #default>
                                    Записаться на курс
                                </template>

                                <template #icon>
                                    <img src="../../assets/components/header/arrow-right.svg" alt="" class="button-icon">
                                </template>
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>

    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
        <div id="successRegistrationToast" class="toast hide bg-success text-white" style="--bs-success-rgb: 82, 169, 156" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong class="me-auto">Успешно</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                Заявка на регистрацию успешно отправлена!
            </div>
        </div>
    </div>

    <div class="modal fade qualification-modal" id="programModal" tabindex="-1" aria-labelledby="programModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h4 class="modal-title qualification-modal__title" id="programModalLabel" v-if="choosenProgram">
                        {{ choosenProgram.title }}
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body mt-0 pt-0" v-if="choosenProgram">
                    <p class="mt-0">Программа рассчитана на {{ choosenProgram.academic_hours }} академических часов</p>

                    <div class="d-flex mb-3">
                        <span class="badge rounded-pill text-bg-danger">{{ programFormat(choosenProgram.program_format) }}</span>
                        <span class="badge rounded-pill text-bg-danger ms-2">с {{ formatDate(choosenProgram.date_start) }}</span>
                    </div>

                    <h5 class="qualification-modal__title">О чём?</h5>

                    <p v-html="choosenProgram.about" />

                    <h5 class="qualification-modal__title">Для кого?</h5>

                    <p v-html="choosenProgram.target" />

                    <h5 class="qualification-modal__title">Результат</h5>

                    <p v-html="choosenProgram.result" />

                    <div class="d-flex justify-content-end flex-wrap">
                        <a v-if="choosenProgram.umd" :href="choosenProgram.umd" class="btn button button--default border m-1" target="_blank">
                            Скачать УМД
                        </a>
                        <a  v-if="choosenProgram.booklet" :href="choosenProgram.booklet" class="btn button button--default border m-1" target="_blank">
                            Подробное описание программы
                        </a>
                        <div data-bs-dismiss="modal">
                            <a class="btn button button--primary m-1" href="#registrationForm">
                                <span>Записаться на курс</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import Button from "@/components/common/Button/Button";
import { onMounted, reactive, ref, watch } from "vue";
import routerMixin from "@/mixins/routerMixin";
import {Toast} from "bootstrap";

export default {
    name: "Qualification",

    components: {
        Nav,
        Button
    },

    setup() {
        const { isMenuVisible, toggleMenu } = routerMixin();

        let form = ref({
            fullname: '',
            organization: '',
            email: '',
            phone: '',
            program: null,
        })

        const nextPage = ref(null)

        const programs = ref([])

        const pageInfo = ref({
            title: '',
            description: '',
            should_show: false
        })

        const filters = reactive({
            program_format: '',
            page: 1,
            order_filter: 'date_start',
        })

        const choosenProgram = ref(null)

        watch(() => filters.program_format, () => loadPrograms())

        watch(() => filters.page, () => loadPrograms())

        watch(() => filters.order_filter, () => loadPrograms())

        const loadPageInfo = async () => {
            const response = await fetch(
                'https://tt.itmo.ru/api/pages/qualification-page/'
            ).then((res) => res.json())

            pageInfo.value = response
        }

        const loadPrograms = async () => {
            console.log('loading...')
            const urlSearchParams = new URLSearchParams()

            for (const filter in filters) {
                console.log('filter', filter)
                urlSearchParams.set(filter, filters[filter])
            }

            const response = await fetch(
                `https://tt.itmo.ru/api/pages/qualification-programs/?${urlSearchParams.toString()}`
            ).then((res) => res.json())

            console.log('res', response)

            programs.value = response.results
            nextPage.value = response.next
        }

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });

            console.log('load programs')
            await loadPageInfo()
            await loadPrograms()
        })

        const goToRegistrationForm = () => {
            const anchor = document.querySelectorAll('a#registrationForm')[0]

            anchor.addEventListener('click', function (e) {
                e.preventDefault()

                document.querySelector('.registrationForm').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            })

            anchor.click()
        }

        const onSubmit = async () => {
            await fetch(
                'https://tt.itmo.ru/api/pages/qualification-requests/', {
                    method: "POST",

                    body: JSON.stringify({
                        fio: form.value.fio,
                        organization: form.value.organization,
                        email: form.value.email,
                        phone: form.value.phone,
                        program: form.value.program,
                    }),

                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            ).then(() => {
                showSuccessToast()

                form.value = {
                    fullname: '',
                    organization: '',
                    email: '',
                    phone: '',
                    program: null,
                }
            })
        }

        const formatDate = (date) => {
            const intlConfig = new Intl.DateTimeFormat('ru-RU', {
                day: 'numeric',
                month: 'long'
            })

            return intlConfig.format(new Date(date))
        }

        const programFormat = (format) => {
            const FORMATS = {
                ONLINE: "онлайн",
                OFFLINE: "очно"
            }

            return FORMATS[format]
        }

        const showSuccessToast = () => {
            const toastEl = document.querySelector('#successRegistrationToast')
            const toast = new Toast(toastEl)
            toast.show()
        }

        const showProgramModal = (program) => {
            choosenProgram.value = program
            form.value.program = program.id
        }

        const paginate = () => {
            filters.page += 1 
        }

        return {
            form,
            programs,
            filters,
            nextPage,
            choosenProgram,
            pageInfo,
            showProgramModal,
            paginate,
            formatDate,
            programFormat,
            goToRegistrationForm,
            onSubmit,
        }
    }
}
</script>
